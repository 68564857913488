import React, { Suspense, useEffect, useState } from 'react';
import { ConfigProvider, Watermark } from 'antd';
import { useRoutes, useNavigate } from 'react-router-dom';
import routes from './routers';
import Loading from '@/components/Loading/loading';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { isBrowser } from 'react-device-detect';
import UserStore from './stores/UserStore';
import CommonStore from '@/stores/CommonStore';
import LanguageStore from '@/stores/LanguageStore';
import { roadDarkAlgorithm, roadLightAlgorithm } from './styles/theme';
import zhCN from 'antd/locale/zh_CN';
import enUS from 'antd/locale/en_US';
import koKR from 'antd/locale/ko_KR';
import { Locale } from 'antd/lib/locale';
import './i18n/config'; // 引用配置文件
import './App.less';
import 'md-editor-rt/lib/style.css';
// import StringUtils from './utils/StringUtils';
import i18n from './i18n/config';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjsZh from 'dayjs/locale/zh-cn';
import dayjsEn from 'dayjs/locale/en-au';
import dayjsKo from 'dayjs/locale/ko';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(isBetween);
// let CountdownTimer: any;

interface IProps {
  user?: UserStore;
  common?: CommonStore;
  language?: LanguageStore;
}

const App: React.FC<IProps> = (props) => {
  const { user, common, language } = props;

  const routing = useRoutes(routes);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [locale, setLocale] = useState<Locale>();
  // const [showCountdown, setShowCountdown] = useState<boolean>(false);
  // const { defaultAlgorithm, darkAlgorithm } = theme;

  useEffect(() => {
    // 初始化友盟SDK
    common?.uMengInit();

    // 判断用户端设备 非PC端浏览器打开项目时跳转到/device-reminder，该页面每天展示一次
    if (!isBrowser && dayjs().format('YYYY-MM-DD') !== common?.mobileEnterDate) {
      navigate('/device-reminder');
    }
  }, []);

  useEffect(() => {
    const lang = language?.language || i18n.language;
    if (lang === 'en') {
      setLocale(enUS);
      dayjs.locale(dayjsEn);
    } else if (lang === 'zh') {
      setLocale(zhCN);
      dayjs.locale(dayjsZh);
    } else {
      setLocale(koKR);
      dayjs.locale(dayjsKo);
    }
  }, [language?.language]);

  return (
    <ConfigProvider
      locale={locale}
      theme={{
        algorithm: common?.theme === 'light' ? roadLightAlgorithm : roadDarkAlgorithm,
        token: {
          colorPrimary: '#C2313A', // 主题色
          colorSuccess: '#00CC66', // 成功色
          colorError: '#FF0000', // 错误色
          // colorFail: '#FF3300', // 失败色
          colorWarning: '#FF9900', // 警告色
          colorLink: '#3399FF', // 链接色
        },
        components: {
          Button: { borderRadius: 8, controlHeightSM: 40, controlHeight: 48, controlHeightLG: 56 },
          Cascader: { controlHeightSM: 40, controlHeight: 48, controlHeightLG: 56 },
          Dropdown: {
            colorBgElevated: common?.theme === 'light' ? '#FFFFFF' : '#171717',
            colorText: common?.theme === 'light' ? '#61616C' : 'rgba(255, 255, 255, 0.65)',
          },
          Form: { controlHeightSM: 40, controlHeight: 48, controlHeightLG: 56 },
          Input: { controlHeightSM: 40, controlHeight: 48, controlHeightLG: 56 },
          Modal: { borderRadius: 24 },
          Radio: { controlHeightSM: 40, controlHeight: 48, controlHeightLG: 56 },
          Select: { controlHeightSM: 40, controlHeight: 48, controlHeightLG: 56 },
          DatePicker: { controlHeightSM: 40, controlHeight: 48, controlHeightLG: 56 },
        },
      }}
    >
      {user?.isTrialVersion ? (
        <Watermark
          content={t('global.trial_watermark')}
          font={{ color: 'rgba(255, 255, 255, 0.09)', fontWeight: 'normal' }}
          zIndex={3000}
        >
          <Suspense fallback={<Loading />}>{routing}</Suspense>
        </Watermark>
      ) : (
        <Suspense fallback={<Loading />}>{routing}</Suspense>
      )}
    </ConfigProvider>
  );
};

export default inject('user', 'common', 'language')(observer(App));
