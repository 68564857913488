// console.log('window.location.protocol', window.location.protocol);

const handleBaseUrl = (local?: string) => {
  let baseUrl = '';

  switch (local) {
    case 'www.adobaro.com': // 生产环境
    case 'v2.adobaro.com': // 生产环境
    case 'adobaro.com': // 生产环境
    case 'allen.adoba.tv': // 临时 生产环境
      // baseUrl = `${protocol}//api.adobaro.com`;
      baseUrl = `https://api.adobaro.com`;
      break;
    default:
      baseUrl = `https://api.dev.adobaro.com`;
      break;
  }

  return { baseUrl };
};

const CONFIG = {
  BASEURL: handleBaseUrl(window.location.host).baseUrl,
};

export default CONFIG;
