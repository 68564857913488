import { makeAutoObservable, action } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';
import planServices, { IUpgradePlanParams } from '@/services/planServices';
import { DisplayDataListType, MemberPlanType } from '@/components/PurchasePlanModal/type';
import {
  CreateUserSubscriptionParams,
  InPayStatusType,
  PayFrequencyType,
  PayMethodType,
  PlanListItemsType,
  PlatformLimitsType,
} from './types';

export default class PlanStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'PlanStore',
      properties: [],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  isTrialUsed: boolean = false; // 是否已参与Trial活动(该权益每个账户仅可使用一次)
  segmentedType: PayFrequencyType = 'M'; // 用户选择的支付周期(当前版本只做月支付)
  planListCustom: MemberPlanType[] = [];
  displayPlanList: DisplayDataListType[] = [];
  platformLimits: PlatformLimitsType = {
    free: 1,
    basic: 3,
  };

  setIsTrialUsed(flag: boolean) {
    this.isTrialUsed = flag;
  }

  saveSegmentedType(type: PayFrequencyType) {
    this.segmentedType = type;
  }

  savePlanListCustom(dataList: MemberPlanType[]) {
    this.planListCustom = dataList;
  }

  saveDisplayPlanList(dataList: DisplayDataListType[]) {
    this.displayPlanList = dataList;
  }

  handleDisplayPlanList(resList: PlanListItemsType[]) {
    // 根据 res.list.level 和 dataListCustom.levelType 将res.list和dataListCustom合并成需要展示的数组
    const planListDisplay: DisplayDataListType[] =
      this.planListCustom.map((customItem: MemberPlanType) => {
        const matchingResItem = resList.find(
          (resItem: PlanListItemsType) =>
            resItem.level === customItem.levelType && resItem.payFrequency === this.segmentedType
        );

        if (matchingResItem) {
          return {
            ...customItem,
            ...(matchingResItem.isActive === true ? matchingResItem : {}), // 仅当matchingResItem的isActive为true时合并
          };
        }
        return customItem;
      }) || [];

    this.saveDisplayPlanList(planListDisplay);
  }

  activePlansList: PlanListItemsType[] = [];
  saveActivePlansList(list: PlanListItemsType[]) {
    this.activePlansList = list;
  }
  async getPlanList() {
    const res = await planServices.getPlanList();

    const activePlans = res?.list.filter((item) => item.isActive === true); // 只展示isActive为true的plan
    this.saveActivePlansList(activePlans);
    activePlans && this.handleDisplayPlanList(activePlans);

    return Promise.resolve(res);
  }

  async getUserPlanList(status: InPayStatusType[]) {
    const params = {
      limit: 999,
      offset: 0,
      inPayStatus: status,
    };

    const res = await planServices.getUserPlanList(params);

    if (res.list && status.includes('PS_SUCCESS') && status.includes('PS_EXPIRED')) {
      this.setIsTrialUsed(res.list.length > 0); // 记录Trial是否已经使用
    }
    return Promise.resolve(res);
  }

  async createUserSubscription(params: CreateUserSubscriptionParams) {
    const res = await planServices.createUserSubscription(params);
    return Promise.resolve(res);
  }

  async updateUserPayStatus(id: number) {
    const res = await planServices.updateUserPayStatus(id);
    return Promise.resolve(res);
  }

  // 升级用户订阅
  async upgradeUserSubscription(planId: number, payMethod: PayMethodType) {
    // 根据getUserPlanList查询plan id
    const params: IUpgradePlanParams = {
      planId,
      payMethod,
    };
    const res = await planServices.upgradeUserSubscription(params);
    return Promise.resolve(res);
  }

  clearStore() {
    this.planListCustom = [];
    this.displayPlanList = [];
    this.isTrialUsed = false;
    this.segmentedType = 'M';
  }
}
