import { makeAutoObservable, action } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';

export default class LanguageStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'LanguageStore',
      properties: ['language'],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  language: string = ''; // 选择的语言

  setLanguage(val: string) {
    this.language = val;
  }
}
