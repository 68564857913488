import materialServices, {
  ICategoryStorageSpaceResponse,
  IGetFileListResponseType,
} from '@/services/materialServices';
import { makeAutoObservable, action } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';
import {
  CreatorUploadFileParams,
  UploadFileParams,
  FileCategoryType,
  UpdateMaterialNameParams,
  DownloadBatchFiles,
} from './types';

export default class MaterialStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'MaterialStore',
      properties: [],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  async getFileList(category: FileCategoryType, offset = 0, limit: number = 9) {
    const params = {
      limit,
      offset,
      category,
    };
    const res = await materialServices.getFileList(params);

    if (offset === 0) {
      this.clearFileList();
    }

    const idArray = res.items.map((item) => item.id);
    this.downloadBatchFiles(idArray);
    this.saveFileList(res);
    return Promise.resolve(res);
  }

  // 处理下载链接
  saveCoverList(list: DownloadBatchFiles[]) {
    const newList = this.fileList.items.map((itemA) => {
      const itemB = list.find((itemB) => itemB.id === itemA.id);

      if (itemB && (itemA.category === 'VIDEO' || itemA.category === 'IMAGE')) {
        return {
          ...itemA,
          url: itemB.url,
        };
      }

      return itemA;
    });

    this.fileList.items = newList;
  }

  hasMore = false;
  limit = 9;
  fileList: IGetFileListResponseType = { count: 0, items: [] };

  // 清空文件列表数据
  clearFileList() {
    this.fileList.items = [];
    this.fileList.count = 0;
  }

  // 保存文件列表数据
  saveFileList(list: IGetFileListResponseType) {
    // 合并当前数据和新请求的数据,并筛选出重复数据
    const newList = this.fileList.items.concat(
      list.items.filter((itemB) => !this.fileList.items.some((itemA) => itemA.id === itemB.id))
    );

    this.hasMore = newList.length < list.count;
    // this.hasMore = true;

    this.fileList.items = newList;
    this.fileList.count = list.count;
  }

  setHasMore(value: boolean) {
    this.hasMore = value;
  }

  async creatorUploadFile(params: CreatorUploadFileParams) {
    const res = await materialServices.creatorUploadFile(params);
    return Promise.resolve(res);
  }

  async uploadFile(params: UploadFileParams) {
    const res = await materialServices.uploadFile(params);
    return Promise.resolve(res);
  }

  async getSingleFileInfo(oid: number) {
    const res = await materialServices.getSingleFileInfo(oid);
    return Promise.resolve(res);
  }

  async deleteSingleFile(oid: number) {
    const res = await materialServices.deleteSingleFile(oid);
    return Promise.resolve(res);
  }

  async downloadSingleFile(oid: number) {
    const res = await materialServices.downloadSingleFile(oid);
    return Promise.resolve(res);
  }

  async downloadBatchFiles(oids: number[]) {
    const params = {
      oids,
    };
    const res = await materialServices.downloadBatchFiles(params);
    this.saveCoverList(res.items);
    return Promise.resolve(res);
  }

  async deleteBatchFiles(oids: number[]) {
    const params = {
      oids,
    };
    const res = await materialServices.deleteBatchFiles(params);
    return Promise.resolve(res);
  }

  async getCategoryStorageSpace() {
    const res = await materialServices.getCategoryStorageSpace();
    this.saveStorageSpace(res);
    return Promise.resolve(res);
  }

  used: number = 0;
  capacity: number = 0;
  video: number = 0;
  image: number = 0;
  font: number = 0;
  subtitle: number = 0;
  bgm: number = 0;
  sound: number = 0;

  saveStorageSpace(storage: ICategoryStorageSpaceResponse) {
    this.used = storage.used ? Number(storage.used) : 0;
    this.capacity = storage.capacity ? Number(storage.capacity) : 0;
    this.video = storage.video ? Number(storage.video) : 0;
    this.image = storage.image ? Number(storage.image) : 0;
    this.font = storage.font ? Number(storage.font) : 0;
    this.subtitle = storage.subtitle ? Number(storage.subtitle) : 0;
    this.bgm = storage.bgm ? Number(storage.bgm) : 0;
    this.sound = storage.sound ? Number(storage.sound) : 0;
  }

  fileListLoading: boolean = false;
  saveFileListLoading(loading: boolean) {
    this.fileListLoading = loading;
  }

  async updateMaterialName(oid: number, params: UpdateMaterialNameParams) {
    const res = await materialServices.updateMaterialName(oid, params);
    return Promise.resolve(res);
  }

  // 存储空间是否不足
  spaceInsufficient: boolean = false;
  saveSpaceInsufficient(flag: boolean) {
    this.spaceInsufficient = flag;
  }

  referenceIds: number[] = [];
  saveReferenceIds(ids: number[]) {
    this.referenceIds = ids;
  }
  // 查询引用中的素材
  async getReferenceMaterial() {
    const res = await materialServices.getReferenceMaterial();
    this.saveReferenceIds(res.list);
    return Promise.resolve(res.list);
  }

  // 清除Store数据
  clearStore() {
    this.clearFileList();

    this.used = 0;
    this.capacity = 0;
    this.video = 0;
    this.image = 0;
    this.font = 0;
    this.subtitle = 0;
    this.bgm = 0;
    this.sound = 0;

    this.fileListLoading = false;
    this.spaceInsufficient = false;
  }
}
