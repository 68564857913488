import { makeAutoObservable, action } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';
import earningServices, {
  IWalletBalanceResponse,
  IDuringEarningParams,
  IAssignEarningParams,
  IWalletUpdateAccountParams,
} from '@/services/earningServices';
import { BankListType } from './types';

export default class EarningStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'EarningStore',
      properties: [],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  balance = 0;
  withdrawable = 0;
  totalWithdrawal = 0;
  async getWalletBalance(uid: number) {
    const res = await earningServices.getWalletBalance();
    this.setWalletBalance(res, uid);
  }

  setWalletBalance(res: IWalletBalanceResponse, uid: number) {
    this.balance = uid === 2991333376 ? 0 : res.amount;
    this.withdrawable = uid === 2991333376 ? 0 : res.withdrawable;
    this.totalWithdrawal = uid === 2991333376 ? 0 : res.totalWithdrawal;
    return Promise.resolve(res);
  }

  // 查询区间收益
  async getDuringEarning(params: IDuringEarningParams) {
    const res = await earningServices.getDuringEarning(params);
    return Promise.resolve(res);
  }

  // 查询区间收益
  async getAssignEarning(params: IAssignEarningParams) {
    const res = await earningServices.getAssignEarning(params);
    return Promise.resolve(res);
  }

  async getBankList() {
    const res = await earningServices.getBankList();
    this.setBankList(res.banks);
    return Promise.resolve(res);
  }

  bankList: BankListType[] = [];
  setBankList(list: BankListType[]) {
    this.bankList = list;
  }

  async getUserAccount() {
    const res = await earningServices.getUserAccount();
    return Promise.resolve(res);
  }

  async updateUserAccount(params: IWalletUpdateAccountParams) {
    const res = await earningServices.updateUserAccount(params);
    return Promise.resolve(res);
  }

  async sendEmailCode() {
    const res = await earningServices.sendEmailCode();
    return Promise.resolve(res);
  }

  async getTaxes() {
    const res = await earningServices.getTaxes();
    return Promise.resolve(res.list);
  }

  async getWithdrawalList(params: any) {
    const res = await earningServices.getWithdrawalList(params);
    return Promise.resolve(res);
  }

  async applyWithdrawal() {
    const res = await earningServices.applyWithdrawal();
    return Promise.resolve(res);
  }

  async confirmWithdrawal(code: string) {
    const res = await earningServices.confirmWithdrawal(code);
    return Promise.resolve(res);
  }

  async getWithdrawalDetail(id: number) {
    const res = await earningServices.getWithdrawalDetail(id);
    return Promise.resolve(res);
  }
}
