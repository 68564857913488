import UserStore from './UserStore';
import CommonStore from './CommonStore';
import LanguageStore from './LanguageStore';
import ContentStore from './ContentStore';
import MaterialStore from './MaterialStore';
import WalletStore from './WalletStore';
import EarningStore from './EarningStore';
import TranslationStore from './TranslationStore';
import PlanStore from './PlanStore';

const stores = {
  user: new UserStore(),
  common: new CommonStore(),
  language: new LanguageStore(),
  content: new ContentStore(),
  material: new MaterialStore(),
  wallet: new WalletStore(),
  earning: new EarningStore(),
  translation: new TranslationStore(),
  plan: new PlanStore(),
};

export default stores;
